// import TextArea from "primevue/textarea";
// import InputText from "primevue/inputtext";
// import SplitButton from "primevue/splitbutton";
import Button from "primevue/button";
import Toolbar from "primevue/toolbar";
const PrimeComponents: Record<string, object> = {
  Toolbar: Toolbar,
  Button: Button
};

export default PrimeComponents;
