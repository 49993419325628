<template>
  <div>
    <ul class="topbar-menu">
      <li><router-link to="/">Home</router-link></li>
      <li><router-link to="/about">About</router-link></li>
      <li><router-link to="/tools">Tools</router-link></li>
      <li><router-link to="/information">Information</router-link></li>
    </ul>
  </div>
  <router-view />
</template>

<style lang="scss"></style>
