<template>
  <div class="home">
    <template v-for="links in linkCollection" :key="links.groupName">
      <h2 class="link-group">{{ links.groupName }}</h2>

      <div class="p-d-flex p-flex-column p-flex-md-row p-flex-wrap">
        <template v-for="link in links.links" :key="link.title">
          <div class="link-box p-mb-2 p-mr-2">
            <a :href="link.url" target="_blank">{{ link.title }}</a>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src

export default defineComponent({
  name: "Home",
  components: {
    // HelloWorld
  },
  props: {
    linkCollection: {
      type: Array,
      default: () => [
        {
          groupName: "Video",
          links: [
            { title: "Youtube", url: "https://www.youtube.com/" },
            { title: "Netflix", url: "https://www.netflix.com/browse" },
            {
              title: "Amazon Prime Video",
              url: "https://www.amazon.co.jp/gp/video/storefront/"
            },
            { title: "Twitch", url: "https://www.twitch.tv/" },
            { title: "AbemaTV", url: "https://abema.tv/" },
            { title: "Hulu", url: "https://www.hulu.jp/" }
          ]
        },
        {
          groupName: "AI",
          links: [
            { title: "ChatGPT", url: "https://chat.openai.com/" },
          ]
        },
        {
          groupName: "SNS",
          links: [
            { title: "Twitter", url: "https://twitter.com/" },
            { title: "Instagram", url: "https://www.instagram.com/" }
          ]
        },
        {
          groupName: "Music",
          links: [
            { title: "SoundCloud", url: "https://soundcloud.com/" },
            { title: "Spotify", url: "https://www.spotify.com/" },
            { title: "bandcamp", url: "https://bandcamp.com/" }
          ]
        },
        {
          groupName: "Shogi",
          links: [
            { title: "将棋ウォーズ", url: "https://shogiwars.heroz.jp/" },
            { title: "将棋ウォーズ棋譜検索", url: "https://www.shogi-extend.com/swars/search" },
            { title: "棋神アナリティクス", url: "https://kishin-analytics.heroz.jp/" },
            
          ]
        },
        {
          groupName: "Game",
          links: [
            { title: "雀魂", url: "https://game.mahjongsoul.com/" },
            { title: "寿司打", url: "https://sushida.net/" },
            { title: "Board Game Arena", url: "https://boardgamearena.com/" },
            { title: "TETR.IO", url: "https://tetr.io/" },
            {
              title: "Ichikio MO-DA",
              url: "http://www.eggazyoutatsu.net/ichikioModa.html"
            }
          ]
        },
        {
          groupName: "Manga",
          links: [
            { title: "少年ジャンプ＋", url: "https://shonenjumpplus.com/" },
            { title: "となりのヤングジャンプ", url: "https://tonarinoyj.jp/" },
            { title: "マガポケ", url: "https://pocket.shonenmagazine.com/" },
            { title: "COMICメテオ", url: "https://comic-meteor.jp/" },
            {
              title: "アルファポリス",
              url: "https://www.alphapolis.co.jp/manga/official"
            },
            {
              title: "ジャンプルーキー！ ",
              url: "https://rookie.shonenjump.com/"
            },
            {
              title: "ヤンマガWeb",
              url: "https://yanmaga.jp/"
            },
            {
              title: "まんがライフWIN",
              url: "http://mangalifewin.takeshobo.co.jp/"
            },
            { title: "モアイ", url: "http://www.moae.jp/" },
            { title: "サンデーうぇぶり", url: "https://www.sunday-webry.com/" },
            { title: "comico", url: "https://www.comico.jp/" },
            { title: "くらげバンチ", url: "https://kuragebunch.com/" },
            { title: "コミックNewtype", url: "https://comic.webnewtype.com/" }
          ]
        },
        {
          groupName: "Novel",
          links: [
            { title: "小説家になろう", url: "https://syosetu.com/" },
            { title: "カクヨム", url: "https://kakuyomu.jp/" },
            {
              title: "アルファポリス",
              url: "https://www.alphapolis.co.jp/novel"
            }
          ]
        },
        {
          groupName: "Dictionary",
          links: [
            { title: "ことわざ辞典", url: "https://kotowaza.jitenon.jp/" }
          ]
        },
        {
          groupName: "Development",
          links: [
            { title: "GitHub", url: "https://github.com/" },
            { title: "Netlify", url: "https://www.netlify.com/" },
            { title: "Vercel", url: "https://vercel.com/" },
            { title: "Heroku", url: "https://heroku.com/" }
          ]
        },
        {
          groupName: "Google",
          links: [
            {
              title: "Search Console",
              url: "https://search.google.com/search-console"
            },
            {
              title: "Analytics",
              url: "https://analytics.google.com/analytics/"
            }
          ]
        },
        {
          groupName: "etc",
          links: [
            {
              title: "官報決算データベース",
              url: "https://catr.jp/"
            }
          ]
        }
      ]
    }
  }
});
</script>
