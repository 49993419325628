import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueGtag from "vue-gtag-next";
// import PrimeVue from "primevue/config";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import PrimeComponents from "./primevue";

import "./assets/app.scss";
const app = createApp(App);
app.use(store).use(router);
// .use(PrimeVue);
app.use(VueGtag, {
  property: {
    id: "G-CCYV1GSDZE"
  }
});
Object.keys(PrimeComponents).map(key => {
  app.component(`${key}`, PrimeComponents[key]);
});
app.mount("#app");
